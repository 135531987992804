import sample1 from "../assets/images/cehfSample.jpg";
import sample2 from "../assets/images/chefSample2.jpg";
import sample3 from "../assets/images/chefSample3.jpg";
import banner1 from "../assets/images/bannerImage1.jpg";
import banner2 from "../assets/images/bannerImage2.jpg";
import banner3 from "../assets/images/bannerImage3.jpg";
import logo from "../assets/images/logo.png";
import logo2 from "../assets/images/logo2.png";
import wave from "../assets/images/wave.png";
import frame1 from "../assets/images/frame1.png";
import frame2 from "../assets/images/frame2.png";
import frame3 from "../assets/images/frame3.png";
import company from "../assets/images/company.jpg";
import NazCookingOil from "../assets/images/brand/NazCookingOil.png";
import PureCookingOil from "../assets/images/brand/PureCookingOil.png";
import FryOlaCookingOil from "../assets/images/brand/FryOlaCookingOil.png";
import SunCookingOil from "../assets/images/brand/SunCookingOil.png";
import PakCookingOil from "../assets/images/brand/PakCookingOil.png";
import SunBanaspati from "../assets/images/brand/SunBanaspati.png";
import PakBanaspati from "../assets/images/brand/PakBanaspati.png";
import NazBanaspati from "../assets/images/brand/NazBanaspati.png";
import PureBanaspati from "../assets/images/brand/PureBanaspati.png";
import KawalityMargarine from "../assets/images/brand/KawalityMargarine.png";
import RoyalMavaMargarine from "../assets/images/brand/RoyalMargarine.png";
import ad1 from "../assets/images/advertisement/pure1.jpg";
import ad2 from "../assets/images/advertisement/naz1.jpg";
import ad3 from "../assets/images/advertisement/pure2.jpg";
import ad4 from "../assets/images/advertisement/naz2.jpg";
import ad5 from "../assets/images/advertisement/ad1.jpg";
import ad6 from "../assets/images/advertisement/ad2.jpg";
import ad7 from "../assets/images/advertisement/ad3.jpg";
import ad8 from "../assets/images/advertisement/ad4.jpg";
import ad9 from "../assets/images/advertisement/ad5.jpg";
import avatar1 from "../assets/images/avatar1.jpg";
import avatar2 from "../assets/images/avatar2.jpg";
import avatar3 from "../assets/images/avatar3.jpg";
import customer1 from "../assets/images/customers/customer1.png";
import customer2 from "../assets/images/customers/customer2.png";
import customer3 from "../assets/images/customers/customer3.png";
import customer4 from "../assets/images/customers/customer4.png";
import certificate1 from "../assets/images/certificate/cert1.png";
import certificate2 from "../assets/images/certificate/cert2.png";
import certificate3 from "../assets/images/certificate/cert3.png";
import certificate4 from "../assets/images/certificate/cert4.png";
import certificate5 from "../assets/images/certificate/cert5.png";
import certificate6 from "../assets/images/certificate/cert6.png";
import certificate7 from "../assets/images/certificate/cert7.png";
import certificate8 from "../assets/images/certificate/cert8.png";
import certificate9 from "../assets/images/certificate/cert9.png";
import nazThumbnail from "../assets/images/brand/new/naz1.jpg";
import pureThumbnail from "../assets/images/brand/new/pure1.jpg";
import sunThumbnail from "../assets/images/brand/new/sun1.jpg";
import pakThumbnail from "../assets/images/brand/new/pak1.jpg";
import pureCanola from "../assets/images/brand/PureCanolaOil.png";
import adNew1 from "../assets/images/advertisement/new1.jpeg";
import adNew2 from "../assets/images/advertisement/new2.jpeg";
import calender from "../assets/images/pdf/calenderPom.pdf";
import bakeryCalender from "../assets/images/pdf/bakeryCalender.pdf";
import pomCalenderThumbnail1 from "../assets/images/pdf/pomCalenderThumbnail1.png";
import pomCalenderThumbnail2 from "../assets/images/pdf/pomCalenderThumbnail2.png";
import pomCalenderThumbnail3 from "../assets/images/pdf/pomCalenderThumbnail3.png";
import pomCalenderThumbnail4 from "../assets/images/pdf/pomCalenderThumbnail4.png";
import bakeryCalenderThumbnail1 from "../assets/images/pdf/bakeryCalenderThumbnail1.png";
import bakeryCalenderThumbnail2 from "../assets/images/pdf/bakeryCalenderThumbnail2.png";
import bakeryCalenderThumbnail3 from "../assets/images/pdf/bakeryCalenderThumbnail3.png";
import bakeryCalenderThumbnail4 from "../assets/images/pdf/bakeryCalenderThumbnail4.png";


export default {
  sample1,
  sample2,
  sample3,
  banner1,
  banner2,
  banner3,
  logo,
  NazCookingOil,
  PureCookingOil,
  FryOlaCookingOil,
  SunCookingOil,
  PakCookingOil,
  SunBanaspati,
  PakBanaspati,
  NazBanaspati,
  PureBanaspati,
  KawalityMargarine,
  RoyalMavaMargarine,
  ad1,
  ad2,
  ad3,
  ad4,
  ad5,
  ad6,
  ad7,
  ad8,
  ad9,
  avatar1,
  avatar2,
  avatar3,
  customer1,
  customer2,
  customer3,
  customer4,
  certificate1,
  certificate2,
  certificate3,
  certificate4,
  certificate5,
  certificate6,
  certificate7,
  certificate8,
  certificate9,
  nazThumbnail,
  pureThumbnail,
  sunThumbnail,
  pakThumbnail,
  logo2,
  wave,
  frame1,
  frame2,
  frame3,
  company,
  pureCanola,
  adNew1,
  adNew2,
  calender,
  bakeryCalender,
  pomCalenderThumbnail1,
  pomCalenderThumbnail2,
  pomCalenderThumbnail3,
  pomCalenderThumbnail4,
  bakeryCalenderThumbnail1,
  bakeryCalenderThumbnail2,
  bakeryCalenderThumbnail3,
  bakeryCalenderThumbnail4,
};
