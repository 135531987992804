import React from 'react';
import "./heading.style.css";

const heading = (props) => {
  return (
    <div className='heading'>
        <h1>{props.title}</h1>
    </div>
  )
}
export default heading;